import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCvAwj5ALw8fvCAWTramLn-bg-PaczRijc",
  authDomain: "rose-s-wedding.firebaseapp.com",
  projectId: "rose-s-wedding",
  storageBucket: "rose-s-wedding.appspot.com",
  messagingSenderId: "212812335783",
  appId: "1:212812335783:web:ad97a9212cbdad1ae6e982",
  measurementId: "G-HMYC9HLTY0"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const appTheme = extendTheme({
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  }
});

root.render(
  <React.StrictMode>
    <ChakraProvider theme={appTheme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(onPerfEntry => {
  const metricName = `metric_${onPerfEntry.name}`;
    logEvent(analytics, metricName, {
      value: onPerfEntry.value,
      id: onPerfEntry.id,
      label: onPerfEntry.name
    });
});

// JENISH: Refered this template https://github.com/cra-template/pwa/blob/main/packages/cra-template-pwa-typescript/README.md

serviceWorkerRegistration.register();
